import { useCallback, useState } from 'react';
import Button from '../../../../components/button';
import { ReactComponent as DocumentIcon } from '../../../../icons/documentIcon.svg';
import { ReactComponent as ExcelIcon } from '../../../../icons/excel.svg';
import { Checkbox } from '@mui/material';
import { useUploadExcelFileMutation } from '../../../../services';
import { createErrorNotification } from '../../../../utils/notifications';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getSampleReport } from '../../../../store/actions/organizations';
import classNames from 'classnames';
import { EXCEL_MIME_TYPES } from '../../../../utils/constant';
import { Field, Form } from 'react-final-form';
import { Tours } from '../../../../components/tour/Tours';

export const CreateProjectCard = ({ isEmptyProject }) => {
  const [optimizeProductNames, setOptimizeProductNames] = useState(false);
  const report = useSelector((state) => state?.organizations?.selectedReport);
  const { id } = useParams();

  const [uploadFile] = useUploadExcelFileMutation();

  const handleUploadFile = useCallback(
    async (values) => {
      const selectedFile = values.file[0];

      const file = new FormData();
      file.append('file', selectedFile);
      file.append('usegpt', optimizeProductNames);
      await uploadFile({ project_id: id, report_id: report?.id, file })
        .unwrap()
        .catch((err) => {
          if (err?.data?.conflict_message) {
            const symbol_limit = err?.data?.conflict_message?.exceeded_products?.[0]?.symbols_limit;
            createErrorNotification(
              `Введено более ${symbol_limit} символов. Необходимо сократить текст наименования товара и повторно загрузить файл`
            );
          } else {
            createErrorNotification('Ошибка при загрузке файла');
          }
        });
    },
    [id, report?.id, optimizeProductNames, uploadFile]
  );

  const validate = (values) => {
    const errors = {};

    if (!values.file || values.file.length === 0) {
      errors.file = 'Файл обязателен';
    } else if (values.file.length > 1) {
      errors.file = 'Можно выбрать только один файл';
      createErrorNotification('Можно выбрать только один файл');
    } else {
      const file = values.file[0];

      if (!EXCEL_MIME_TYPES.includes(file.type)) {
        errors.file = 'Допустимы только файлы .xls или .xlsx';
        createErrorNotification('Допустимы только файлы .xls или .xlsx');
      }
    }
    return errors;
  };

  return (
    <div
      className={classNames('tw-flex tw-bg-white tw-rounded-lg tw-w-full', {
        'tw-flex-col tw-mt-[40px] tw-mx-auto tw-max-w-fit tw-items-start tw-gap-3 tw-py-5 tw-px-7': isEmptyProject,
        'tw-flex-row tw-max-w-[708px] tw-items-center tw-gap-[38px] tw-py-3 tw-px-[18px]': !isEmptyProject,
      })}
    >
      <Tours sectionName="emptyProjectTour" />
      {isEmptyProject && (
        <p className="tw-text-[20px] tw-text-[#191919] tw-leading-[24px] tw-font-semibold">Загрузите файл</p>
      )}
      <Button
        variant="text"
        onClick={() => getSampleReport()}
        className="tw-w-fit tw-flex tw-flex-row tw-items-center tw-gap-[2px] tw-p-0 hover:tw-bg-transparent"
      >
        <DocumentIcon />
        <p className="tw-text-[#134B98] tw-text-sm tw-font-medium tw-leading-[18px]">Скачать документ</p>
      </Button>
      <Form onSubmit={handleUploadFile} validate={validate}>
        {({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field name="file">
                {({ input }) => (
                  <Button
                    variant="text"
                    className="tw-w-fit tw-flex tw-flex-row tw-items-center tw-gap-[2px] tw-p-0 hover:tw-bg-transparent"
                  >
                    <label className="tw-flex tw-flex-row tw-items-center tw-gap-[2px]" data-tour="uploadDoc">
                      <ExcelIcon />
                      <p className="tw-text-[#134B98] tw-text-sm tw-font-medium tw-leading-[18px]">
                        Загрузить по шаблону
                      </p>
                      <input
                        type="file"
                        name="file"
                        multiple={false}
                        accept=".xlsx, .xls"
                        onChange={(e) => {
                          input.onChange(e.target.files);
                          handleSubmit();
                          e.target.value = null;
                        }}
                        className="tw-hidden"
                      />
                    </label>
                  </Button>
                )}
              </Field>
            </form>
          );
        }}
      </Form>
      <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
        <Checkbox
          className="tw-p-0"
          value={optimizeProductNames}
          onChange={() => setOptimizeProductNames((prev) => !prev)}
        />
        <p className="tw-text-sm tw-text-[#191919] tw-leading-[18px] tw-font-medium">Оптимизировать названия</p>
      </div>
    </div>
  );
};
