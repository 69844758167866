import { Box, Dialog, Tab } from '@mui/material';
import { useDialog } from '../../../../providers/dialog.provider';
import { ReactComponent as CloseIcon } from '../../../../icons/closeIcon.svg';
import { ReactComponent as ArrowLeft } from '../../../../icons/arrowLeftIcon.svg';
import Button from '../../../../components/button';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useMemo, useState } from 'react';
import Table from '../../../../components/table';
import { Form } from 'react-final-form';
import Input from '../../../../components/input';
import { useSelector } from 'react-redux';
import { priceFormatter } from '../../../../utils/priceFormatter';
import { CALCULATED_DIMENSHIONS_FROM, DELIVERY_SERVICES } from '../../../../utils/constant';
import { convertStringToURL } from '../../../../utils/common';
import { ImageWithFallback } from '../../../../components/imageWithFallback';
import convert from 'convert-units';

export const ProductInfo = ({ delivery_info, source, onEdit, productId, projectId }) => {
  const dialog = useDialog();

  const organization = useSelector((state) => state.organizations.selected);
  const [editing, setEditing] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);

  const handleCloseDialog = () => {
    dialog.close();
  };
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleCancelEdit = () => {
    setEditing(false);
  };

  const handleFormSubmit = async (values) => {
    let { correct_height, correct_length, correct_weight, correct_width } = values;

    const body = {
      correct_height: Number(correct_height),
      correct_length: Number(correct_length),
      correct_weight: Number(correct_weight),
      correct_width: Number(correct_width),
      height: source?.height,
      length: source?.length,
      organization_id: organization?.id,
      product_id: productId,
      project_id: projectId,
      url: source?.url,
      weight: source?.weight,
      width: source?.width,
      name: source?.title,
    };

    await onEdit(body).then(() => {
      setEditing(false);
      dialog.close()
    });
  };
  return (
    <Dialog
      open={dialog.visibility}
      sx={{
        '& .MuiPaper-root': {
          minWidth: '584px',
          margin: '32px',
          height: 'fit-content',
          maxHeight: 'calc(100% - 64px)',
        },
      }}
    >
      <div className="tw-flex tw-flex-col tw-bg-white tw-rounded-lg tw-relative ">
        {!editing ? (
          <>
            <Button
              variant="text"
              onClick={handleCloseDialog}
              className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-absolute tw-top-[14px] tw-right-[14px]"
            >
              <CloseIcon />
            </Button>
            <p className="tw-text-[#191919] tw-p-6 tw-text-[18px] tw-font-medium tw-leading-[21px]">
              Доставка и характеристики товара
            </p>
            <TabContext value={currentTab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', padding: '0 24px' }}>
                <TabList onChange={handleChange}>
                  <Tab className="tw-normal-case" label={'Варианты доставки'} value={1} />
                  <Tab className="tw-normal-case" label={'Характеристики товара'} value={2} />
                </TabList>
              </Box>
              <TabPanel value={1}>
                <DeliveryPricesByService delivery_info={delivery_info} />
              </TabPanel>
              <TabPanel value={2}>
                <ProductDimenshions source={source} onChangeEdit={setEditing} />
              </TabPanel>
            </TabContext>
          </>
        ) : (
          <>
            <EditingInfo onEdit={handleFormSubmit} source={source} onCancelEdit={handleCancelEdit} />
          </>
        )}
      </div>
    </Dialog>
  );
};

const columns = {
  delivery_service_id: {
    label: 'Транспортная компания',
    renderBodyCell: (value) => {
      return (
        <p className="tw-text-[#191919] tw-font-medium tw-leading-[18px] tw-text-sm">
          {DELIVERY_SERVICES[value.delivery_service_id]}
        </p>
      );
    },
  },
  price: {
    label: 'Цена',
    renderBodyCell: (value) => {
      return (
        <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">
          {!value?.price ? '-' : priceFormatter(value?.price, 2)}
          <span className="tw-ml-1">₽</span>
        </p>
      );
    },
  },
};

const DeliveryPricesByService = ({ delivery_info }) => {
  const validDeliveryPrices = useMemo(
    () => delivery_info.filter((item) => item.price !== 0 && item.price !== null),
    [delivery_info]
  );

  return (
    <>
      <Table rows={validDeliveryPrices} columns={columns} />
    </>
  );
};

const ProductDimenshions = ({ source, onChangeEdit }) => {
  const dialog = useDialog();

  const validUrl = convertStringToURL(source?.url);

  return (
    <div className="tw-flex tw-flex-col">
      <div className="tw-flex tw-flex-row tw-gap-[14px] tw-mb-6">
        <ImageWithFallback
          className="tw-rounded-[4px] tw-max-h-[70px] tw-min-h-[70px] tw-max-w-[70px] tw-min-w-[70px] tw-aspect-square"
          srcImg={source?.pics?.[0]}
          fallbackSrc={'/images/placeholders/other.png'}
          alt="source_img"
        />
        <div className="tw-flex tw-flex-col tw-gap-5">
          {validUrl && (
            <a
              className="tw-text-[#134B98] tw-text-sm tw-leading-[18px] tw-font-medium"
              href={validUrl.href}
              target="_blank"
              rel="noreferrer"
            >
              {validUrl.hostname}
            </a>
          )}
          <p className="tw-text-[#191919] tw-text-sm tw-max-w-[452px]">{source?.title}</p>
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-2 tw-mb-[108px]">
        <div className="tw-flex tw-flex-row tw-gap-[22px]">
          <div className="tw-flex tw-flex-col tw-gap-1">
            <p className="tw-text-[#666666] tw-text-xs tw-leading-4 tw-font-medium">Длина (мм)</p>
            <p className="tw-text-[#191919] tw-text-[18px] tw-font-semibold tw-leading-[22px]">
              {source?.length ? convert(source?.length).from('cm').to('mm').toFixed(0) : '-'}
            </p>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1">
            <p className="tw-text-[#666666] tw-text-xs tw-leading-4 tw-font-medium">Высота (мм)</p>
            <p className="tw-text-[#191919] tw-text-[18px] tw-font-semibold tw-leading-[22px]">
              {source?.height ? convert(source?.height).from('cm').to('mm').toFixed(0) : '-'}
            </p>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1">
            <p className="tw-text-[#666666] tw-text-xs tw-leading-4 tw-font-medium">Ширина (мм)</p>
            <p className="tw-text-[#191919] tw-text-[18px] tw-font-semibold tw-leading-[22px]">
              {source?.width ? convert(source?.width).from('cm').to('mm').toFixed(0) : '-'}
            </p>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1">
            <p className="tw-text-[#666666] tw-text-xs tw-leading-4 tw-font-medium">Вес (гр)</p>
            <p className="tw-text-[#191919] tw-text-[18px] tw-font-semibold tw-leading-[22px]">
              {source?.weight ? convert(source?.weight).from('g').to('g').toFixed(0) : '-'}
            </p>
          </div>
        </div>
        <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">
          Расчет габаритов был произведен {CALCULATED_DIMENSHIONS_FROM[source?.dimensions_from]}
        </p>
      </div>
      <div className="tw-flex tw-flex-row tw-items-center tw-w-full tw-justify-end tw-gap-6 ">
        <Button
          variant="text"
          onClick={() => dialog.close()}
          className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-[#666666] tw-text-sm tw-leading-[18px] tw-font-semibold"
        >
          Закрыть
        </Button>
        <Button
          onClick={() => onChangeEdit(true)}
          className="tw-bg-[#134B98] tw-px-4 tw-py-3 tw-w-fit tw-text-sm tw-leading-[18px] tw-font-semibold tw-rounded-lg"
        >
          Изменить габариты
        </Button>
      </div>
    </div>
  );
};

const EditingInfo = ({ onEdit, onCancelEdit, source }) => {
  return (
    <>
      <div className="tw-p-6">
        <h4 className="tw-text-[#191919] tw-text-base tw-leading-5 tw-font-semibold tw-mb-6">
          Жалоба на габариты товара
        </h4>
        <div className="tw-flex tw-flex-col tw-items-start tw-gap-2 tw-mb-8">
          <div className="tw-flex tw-flex-row tw-items-start tw-gap-[22px]">
            <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
              <p className="tw-text-xs tw-leading-[18px] tw-text-[#666666] tw-font-medium">Длина (мм)</p>
              <p className="tw-text-[#191919] tw-text-lg tw-leading-[22px] tw-font-semibold">
                {source?.length ? convert(source?.length).from('cm').to('mm') : '-'}
              </p>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
              <p className="tw-text-xs tw-leading-[18px] tw-text-[#666666] tw-font-medium">Высота (мм)</p>
              <p className="tw-text-[#191919] tw-text-lg tw-leading-[22px] tw-font-semibold">
                {source?.height ? convert(source?.height).from('cm').to('mm') : '-'}
              </p>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
              <p className="tw-text-xs tw-leading-[18px] tw-text-[#666666] tw-font-medium">Ширина (мм)</p>
              <p className="tw-text-[#191919] tw-text-lg tw-leading-[22px] tw-font-semibold">
                {source?.width ? convert(source?.width).from('cm').to('mm') : '-'}
              </p>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
              <p className="tw-text-xs tw-leading-[18px] tw-text-[#666666] tw-font-medium">Вес (гр)</p>
              <p className="tw-text-[#191919] tw-text-lg tw-leading-[22px] tw-font-semibold">
                {source?.weight ? convert(source?.weight).from('g').to('g') : '-'}
              </p>
            </div>
          </div>
          <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">
            Расчет габаритов был произведен {CALCULATED_DIMENSHIONS_FROM[source?.dimensions_from]}
          </p>
        </div>
        <Form onSubmit={(value) => onEdit(value)}>
          {({ handleSubmit, form, values }) => (
            <>
              <div className="tw-flex tw-flex-col tw-gap-[18px] ">
                <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-semibold">
                  Укажите нужные значения
                </p>

                <form onSubmit={handleSubmit}>
                  <div className="tw-flex tw-flex-row tw-items-center tw-flex-wrap tw-gap-6 tw-mb-[62px]">
                    <div className="tw-flex tw-flex-col tw-gap-[10px] tw-items-start tw-max-w-[116px]">
                      <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Длина (мм)</p>
                      <Input form={form} value={values['correct_length']} name="correct_length" type="number" />
                    </div>
                    <div className="tw-flex tw-flex-col tw-gap-[10px] tw-items-start tw-max-w-[116px]">
                      <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Высота (мм)</p>
                      <Input form={form} value={values['correct_height']} name="correct_height" type="number" />
                    </div>
                    <div className="tw-flex tw-flex-col tw-gap-[10px] tw-items-start tw-max-w-[116px]">
                      <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Ширина (мм)</p>
                      <Input form={form} value={values['correct_width']} name="correct_width" type="number" />
                    </div>
                    <div className="tw-flex tw-flex-col tw-gap-[10px] tw-items-start tw-max-w-[116px]">
                      <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Макс. вес (гр)</p>
                      <Input form={form} value={values['correct_weight']} name="correct_weight" type="number" />
                    </div>
                    {/* <div className="tw-flex tw-flex-col tw-gap-[10px] tw-items-start tw-max-w-[116px]">
                      <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Ед. размера</p>
                      <div className="tw-w-[116px]">
                        <Field name="size_units" component={SelectMUI} form={form} options={['Метр', 'см']} required />
                      </div>
                    </div>
                    <div className="tw-flex tw-flex-col tw-gap-[10px] tw-items-start tw-max-w-[116px]">
                      <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Ед. массы</p>
                      <div className="tw-w-[116px]">
                        <Field name="weight_units" component={SelectMUI} form={form} options={['кг', 'г']} required />
                      </div>
                    </div> */}
                  </div>
                  <div className="tw-flex tw-flex-row tw-items-center tw-justify-end tw-gap-6 tw-mt-auto">
                    <Button
                      className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-[#666666] tw-leading-[18px] tw-text-sm hover:tw-text-[#7F7F7F]"
                      variant="text"
                      onClick={onCancelEdit}
                    >
                      <ArrowLeft className="tw-rotate-180" />
                      Назад
                    </Button>
                    <Button
                      className="tw-w-fit tw-px-4 tw-py-3 tw-rounded-lg tw-text-sm tw-leading-[18px]"
                      type="submit"
                    >
                      Изменить габариты
                    </Button>
                  </div>
                </form>
              </div>
            </>
          )}
        </Form>
      </div>
    </>
  );
};
