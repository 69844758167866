import { ReactComponent as CloseIcon } from '../../../../icons/closeIcon.svg';
import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import { useDialog } from '../../../../providers/dialog.provider';


const OrganizationDetail = ({ label, value, hideMt = false }) => (
  <>
    <p className={`tw-text-[#666] tw-font-normal tw-text-sm ${hideMt ? '' : 'tw-mt-[18px]'}`}>{label}</p>
    <p className="tw-text-[#191919] tw-text-[16px] tw-font-normal">{value || 'Не указано'}</p>
  </>
);


export const CardOrganizationModal = ({ organizations }) => {
  const dialog = useDialog();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return(
    <Dialog
      open={dialog.visibility}
      onClose={dialog.close}
      sx={{
        '& .MuiPaper-root': {
          width: mobile ? '100%' : '507px',
          margin: mobile ? '0px' : '32px',
          height: mobile ? '100%' : 'fit-content',
          maxHeight: mobile ? '100%' : 'calc(100% - 64px)',
        },
      }}
    >
      <div className="tw-flex tw-flex-col tw-relative tw-pt-6 tw-w-full tw-overflow-hidden">
        <CloseIcon
          className="tw-absolute tw-top-[14px] tw-right-[14px] tw-cursor-pointer"
          onClick={dialog.close}
        />
        <p className="tw-text-[#191919] tw-leading-5 tw-text-[1.5rem] tw-font-semibold tw-px-6">Карточка организации</p>
        <hr className="tw-mt-4 sm:tw-flex md:tw-hidden"/>
        <div className="tw-mt-6 tw-px-6 tw-pb-6">
          <OrganizationDetail label="Название" hideMt value={organizations?.name} />
          <OrganizationDetail label="ИНН" value={organizations?.inn} />
          <OrganizationDetail label="Муниципальное образование" value={organizations?.municipality} />
          <OrganizationDetail
            label="Признак"
            value={
              organizations?.type?.length > 0 ? (
                organizations.type.map((item, index) => (
                  <span key={index} className="tw-block">
                    {item}
                  </span>
                ))
              ) : (
                'Не указан'
              )
            }
          />
        </div>
      </div>
    </Dialog>
  )
}