import GeneralLayout from '../../components/general-layout';
import { SwitchMUI } from '../../components/switch';
import React, { useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import { checkIsAdmin } from '../../utils/common';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import UsersStorage from './users/users';
import StorageOrganizations from './organizations/organizations';
import {
  useGetAutoScreenshotCleanupStatusQuery,
  useToggleAutoScreenshotCleanupStatusMutation
} from '../../services/adminAPIs';
import { createErrorNotification } from '../../utils/notifications';
import Loader from '../../components/loader';

export const Storage = () => {
  const [activeTab, setActiveTab] = useState('users');
  const user = useSelector((state) => state.auth.user);
  const isAdmin = checkIsAdmin(user);
  const { data, isLoading, isError } = useGetAutoScreenshotCleanupStatusQuery(undefined, {refetchOnMountOrArgChange: true});
  const [toggleStatusMutation] = useToggleAutoScreenshotCleanupStatusMutation();
  const [storageClear, setStorageClear] = useState(false);


  useEffect(() => {
    if (data?.status !== undefined) {
      setStorageClear(data.status);
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      createErrorNotification("Временные неполадки в отображении кнопки \"Автоматическая очистка\" ");
    }
  }, [isError]);

  const handleTabChange = (e, value) => {
    setActiveTab(value);
  };
  if (!isAdmin) {
    return <Redirect to="/" />;
  }

  const handleAutomaticCleaningSwitch = async () => {
    const previousStatus = storageClear;
    const newStatus = !storageClear;
    setStorageClear(newStatus);

    try {
      const res = await toggleStatusMutation({status: newStatus}).unwrap();
      setStorageClear(res.status);
    } catch (err) {
      setStorageClear(previousStatus);
      createErrorNotification('При изменении статуса произошла ошибка');
    }
  };


  return (
    <GeneralLayout>
      <h1 className="tw-text-[2rem] tw-font-semibold tw-leading-[2.375rem] tw-text-[#191919] tw-mb-8">
        Хранилище
      </h1>
      <div className="tw-flex tw-items-center tw-gap-3 tw-mb-8">
        {isLoading ? (<div><Loader /></div>) : (<SwitchMUI initialValue={storageClear} handleChange={handleAutomaticCleaningSwitch} />)}
        <p className="tw-text-[#191919] tw-leading-[20px] tw-text-[16px] tw-font-semibold">
          Автоматическая очистка хранилища спустя 30 дней
        </p>
      </div>
      <div className="tw-w-full tw-bg-white tw-rounded-lg tw-px-6">
        <TabContext value={activeTab}>
          <div className="tw-w-full tw-border-b tw-border-b-[#DDDDDD] tw-pt-[10px] ">
            <TabList
              onChange={handleTabChange}
              sx={{
                '& .MuiTabs-flexContainer': {
                  gap: '24px',
                },
              }}
            >
              <Tab label="Пользователи" value="users" disableRipple className="tw-normal-case tw-px-0" />
              <Tab label="Организации" value="organizations" disableRipple className="tw-normal-case tw-px-0" />
            </TabList>
          </div>
          <TabPanel value="users" className="tw-p-0">
            <div className="tw-pt-4">
              <UsersStorage/>
            </div>
          </TabPanel>
          <TabPanel value="organizations" className="tw-p-0">
            <div className="tw-pt-4">
              <StorageOrganizations />
            </div>
          </TabPanel>
        </TabContext>
      </div>
    </GeneralLayout>
  )
}