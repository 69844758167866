import { useDialog } from '../../providers/dialog.provider';
import { Dialog } from '@mui/material';
import { downloadFile } from '../../utils/request';
import { createErrorNotification } from '../../utils/notifications';
import Button from '../button';

export const LogisticGuide = () => {
  const dialog = useDialog();
  const getUserGuide = async () => {
    await downloadFile('/api/organizations/logistic_guide', 'Руководство пользователя по логистике.pdf').then(() => {
      dialog.close()
    }).catch((err) => {
      console.error(err);
      createErrorNotification('Произошла ошибка при скачивании руководства');
    });
  };
  return (
    <Dialog
      open={dialog.visibility}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '8px',
          maxWidth: '436px',
        },
      }}
    >
      <div className="tw-flex tw-flex-col tw-p-6 tw-bg-white">
        <h4 className="tw-text-[#191919] tw-leading-5 tw-font-semibold tw-mb-4">Руководство по логистике</h4>
        <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-mb-14">
          Если у вас возникли вопросы по логистике или вам непонятно, как сделать расчет, то вы можете скачать подробную
          информацию и ознакомиться с ней.
        </p>
        <div className="tw-flex tw-flex-row tw-w-full tw-items-center tw-justify-end tw-gap-6">
          <Button
            variant="text"
            className="tw-text-[#666666] tw-leading-[18px] tw-w-fit tw-text-sm tw-p-0 hover:tw-bg-transparent"
            onClick={() => dialog.close()}
          >
            Закрыть
          </Button>
          <Button className="tw-w-fit tw-rounded-lg tw-px-4 tw-py-3 tw-font-medium" onClick={() => getUserGuide()}>
            Скачать руководство
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
