import { AddressSuggestions } from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';
import { getWordEnding1, getWordEnding3 } from '../../../../utils/common';
import Button from '../../../../components/button';
import { getReportsFile } from '../../../../store/actions/organizations';
import { useCallback, useState } from 'react';
import { priceFormatter } from '../../../../utils/priceFormatter';
import { DELIVERY_SERVICES, PRODUCT_LOADING_STATES } from '../../../../utils/constant';
import Loader from '../../../../components/loader';
import { REPORT_STATUS } from '../../../../utils/constant.js';
import { Tooltip } from '../../../../components/tooltip/Tooltip.jsx';
import { useDialog } from '../../../../providers/dialog.provider.js';
import { DeliveryInfoModal } from './modal/DeliveryModal.jsx';

export const TotalPriceCard = ({ handleChangeAddress, report }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const dialog = useDialog();
  const availableToDownload = PRODUCT_LOADING_STATES.includes(report?.status);

  const handleDownloadReport = async () => {
    try {
      setIsDownloading(true);
      if (!report?.id) return null;
      await getReportsFile(report.id);
    } catch (err) {
      console.error(err);
    } finally {
      setIsDownloading(false);
    }
  };
  const showOverallDeliveryInfo = useCallback(() => {
    dialog.open(<DeliveryInfoModal delivery_info={report?.dict_of_deliveries} />);
  }, [dialog, report?.dict_of_deliveries]);
  const isAllProductsFound = report && report?.total_count - report?.success_product_count === 0;

  const haveProblemsWithDimensions = report?.sources_with_incorrect_dimensions > 0;

  const amountUnsuccessfullProducts = Number.isNaN(report?.total_count - report?.success_product_count)
    ? 0
    : report?.total_count - report?.success_product_count;
  const isDeliveryInfoAvailable =
    Object.keys(report?.dict_of_deliveries ?? {}).length !== 0 && report?.delivery_status !== 'sum_of_avg_deliveries';
  return (
    <div className="tw-flex tw-flex-col tw-bg-white tw-pt-[14px] tw-pb-6 tw-px-4 tw-rounded-lg tw-w-full">
      <div className="tw-flex tw-flex-col tw-items-start tw-gap-[6px] tw-mb-4 tw-w-full">
        <p className="tw-text-[#666666] tw-text-sm tw-font-medium tw-leading-[18px]">Адрес доставки</p>
        {report && (
          <div className="tw-w-full">
            <AddressSuggestions
              key={report?.delivery_address}
              token={process.env.REACT_APP_DADATA_API_KEY}
              delay={1000}
              defaultQuery={report?.delivery_address}
              onChange={(address) => handleChangeAddress(address?.value, address?.data?.postal_code)}
              count={5}
              inputProps={{
                disabled: report?.delivery_processing_status === 'processing',
                className:
                  'tw-p-[14px] tw-w-full tw-rounded-xl tw-border-[#D5D5D5] tw-border disabled:tw-text-[#CCCCCC]',
                placeholder: 'Введите адрес доставки',
              }}
            />
          </div>
        )}
      </div>
      <div
        className={`tw-flex tw-flex-col tw-items-start tw-gap-[6px] ${isAllProductsFound ? 'tw-mb-[28px]' : 'tw-mb-2'}`}
      >
        <p className="tw-text-sm tw-font-medium tw-leading-[18px] tw-text-[#191919] ">
          Выбрано {report?.total_count} {getWordEnding1('товар', report?.total_count)} для обоснования
        </p>
        {!isAllProductsFound && (
          <p className="tw-text-[#C12026] tw-font-medium tw-leading-[18px] tw-text-sm ">
            Цена изменится после обработки {amountUnsuccessfullProducts}{' '}
            {getWordEnding1('товар', amountUnsuccessfullProducts)}
          </p>
        )}
      </div>
      <div className="tw-flex tw-flex-col tw-items-start tw-gap-1 tw-mb-3">
        <p className="tw-text-[#666666] tw-text-sm tw-font-medium tw-leading-[18px]">Итоговая цена товаров</p>
        <p className="tw-text-[#191919] tw-text-[32px] tw-leading-[38px] tw-font-semibold tw-flex tw-flex-row tw-items-center tw-gap-1">
          {priceFormatter(report?.avg_price ?? 0, 2)}
          <span className="tw-text-[24px] tw-leading-[28px]">₽</span>
          {report?.status === REPORT_STATUS.PROCESSING && <Loader iconClassName="tw-w-6 tw-h-6" />}
        </p>
      </div>
      <div className="tw-flex tw-flex-col tw-items-start tw-gap-1">
        <p className="tw-text-[#666666] tw-text-sm tw-font-medium tw-leading-[18px] tw-flex tw-flex-row tw-items-center tw-gap-1">
          Логистика
          <Tooltip
            title={`Рассчитано исходя из условия отправки
                  всех товаров проекта сборным грузом
                  по указанному адресу доставки. ${
                    DELIVERY_SERVICES?.[report?.delivery_status]
                      ? `Транспортная компания ${DELIVERY_SERVICES?.[report?.delivery_status]}.`
                      : 'Расчет был произведен методом суммирования'
                  } `}
          />
        </p>

        <p className="tw-text-[#191919] tw-text-[24px] tw-leading-[28px] tw-font-semibold tw-flex tw-flex-row tw-items-center tw-gap-1">
          {priceFormatter(report?.avg_cost_of_delivery ?? 0, 2)}
          <span className="tw-text-[18px] tw-leading-[22px]">₽</span>
          {report?.delivery_processing_status === REPORT_STATUS.PROCESSING && <Loader iconClassName="tw-w-6 tw-h-6" />}
        </p>
      </div>
      {haveProblemsWithDimensions && (
        <p className="tw-text-[#C12026] tw-text-sm tw-font-medium tw-leading-[18px] tw-text-left">
          Не удалось считать габариты у {report?.sources_with_incorrect_dimensions}{' '}
          {getWordEnding3('товар', report?.sources_with_incorrect_dimensions)}
        </p>
      )}
      {isDeliveryInfoAvailable && (
        <Button
          variant="text"
          className="tw-w-fit tw-p-0 hover:tw-bg-transparent disabled:tw-text-[#8CA6C1] tw-text-[#134B98] tw-text-sm tw-leadiong-4 tw-font-medium"
          onClick={showOverallDeliveryInfo}
        >
          Информация о доставке
        </Button>
      )}
      <Button
        onClick={handleDownloadReport}
        disabled={isDownloading && !availableToDownload}
        className="tw-text-center tw-mt-6 tw-py-3 tw-rounded-lg tw-text-sm tw-font-semibold tw-leading-[18px] tw-bg-[#134B98] disabled:tw-bg-[#8CA6C1]"
      >
        Выгрузить excel отчет
      </Button>
    </div>
  );
};
