import { Checkbox, FormControlLabel } from '@mui/material';
import { Field } from 'react-final-form';

export const CheckboxGroup = ({ options, fields, ...props }) => {
  return Object.entries(options).map(([key, label]) => (
    <div key={key}>
      <FormControlLabel
        label={label}
        key={key}
        control={
          <Field
            name={`${fields.name}`}
            value={key}
            type='checkbox'
            {...props}
            render={({input}) => <Checkbox {...input} />}
          />
        }
      />
    </div>
  ));
};
